<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="users"
      item-key="users.id"
      :loading="isLoading"
      :server-items-length="totalUsers"
      :options.sync="options"
      loading-text="Loading... Please wait"
      class="elevation-1"
    >
      <!-- <template #top>
        <v-toolbar flat color="white">
          <v-toolbar-title class="h1 mb-2 mt-2">Manage Users</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-toolbar flat color="white">
          <div>
            <v-text-field
              class="mt-4"
              v-model="userQuery"
              color="#F06835"
              outlined
              dense
              label="Type name or email"
              required
            ></v-text-field>
          </div>
        </v-toolbar>
      </template> -->

      <template #[`item.actions`]="{ item }">
        <v-tooltip bottom>
          <template #activator="{on, attrs}">
            <router-link :to="`/edit-user/${item.id}`">
              <v-icon small class="mr-2" v-bind="attrs" v-on="on">
                mdi-pencil-outline
              </v-icon>
            </router-link>
          </template>
          <span>Edit</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template #activator="{on, attrs}">
            <v-icon
              small
              @click="impersonate(item.id)"
              v-bind="attrs"
              v-on="on"
            >
              mdi-exit-to-app
            </v-icon>
          </template>
          <span>Impersonate</span>
        </v-tooltip>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { GET_PAGED, POST_DATA, API_USERS } from "@/core/store/crud.module";
import { SET_BREADCRUMB } from "@/core/store/breadcrumbs.module";

export default {
  data: () => ({
    isLoading: false,
    headers: [
      {
        text: "The Name of the User",
        align: "start",
        value: "fullName"
      },
      { text: "Email", value: "email" },
      { text: "Plan", value: "subscription.plan", sortable: false },
      { text: "Status", value: "subscription.status", sortable: false },
      { text: "Actions", value: "actions", sortable: false }
    ],
    users: [],
    totalUsers: null,
    userQuery: "",
    options: {
      page: 1,
      itemsPerPage: 10,
      sortBy: ["fullName"],
      sortDesc: [true]
    }
  }),
  watch: {
    userQuery: async function() {
      if (this.userQuery && this.userQuery.length > 0) {
        await this.getUsers();
      } else {
        this.users = [];
      }
    },
    "options.sortBy": async function() {
      if (this.userQuery && this.userQuery.length > 0) {
        await this.getUsers();
      } else {
        this.users = [];
      }
    }
  },
  async mounted() {
    this.isLoading = true;
    await this.$store.dispatch(SET_BREADCRUMB, [{ title: "Manage Users" }]);
    this.isLoading = false;
  },
  methods: {
    async getUsers() {
      this.isLoading = true;
      await this.$store
        .dispatch(GET_PAGED, {
          listName: API_USERS,
          params: {
            query: this.userQuery,
            skip: this.options.itemsPerPage * (this.options.page - 1),
            take:
              this.options.itemsPerPage === -1
                ? this.totalUsers
                : this.options.itemsPerPage,
            sort: this.sort
          }
        })
        .then(response => {
          if (response.status >= 200 || response.status <= 204) {
            this.users = response.data.users;
            this.totalUsers = response.data.total;
          } else {
            this.$snackbar.showMessage({
              content: response.data || "Something went wrong!",
              color: "red"
            });
          }
        });
      this.isLoading = false;
    },
    async impersonate(userId) {
      await this.$store
        .dispatch(POST_DATA, {
          listName: `${API_USERS}/${userId}/impersonate`
        })
        .then(response => {
          if (response.status >= 200 || response.status <= 204) {
            window.open(
              `${process.env.VUE_APP_DASHBOARD_URL}/impersonate?token=${response.data.access_token}`,
              "_blank"
            );
          } else {
            this.$snackbar.showMessage({
              content: response.data || "Something went wrong!",
              color: "red"
            });
          }
        });
    }
  },
  computed: {
    sort() {
      if (
        this.options.sortBy &&
        this.options.sortBy[0] &&
        this.options.sortBy[0].length > 0
      ) {
        if (this.options.sortBy[0] === "fullName") {
          return `firstName ${this.options.sortDesc[0] ? "desc" : "asc"}`;
        } else {
          return `${this.options.sortBy[0]} ${
            this.options.sortDesc[0] ? "desc" : "asc"
          }`;
        }
      }
      return "";
    }
  }
};
</script>
